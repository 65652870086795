import React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/layout"
import NewsThumbnail from "../../../components/elements/news-thumbnail"
import DualSplit from "../../../components/sections/dual-split"
import { useTranslation } from "react-i18next"
import Seo from "../../../components/seo"

const NewsHomepage = ({ data }) => {
  const news = data.news.edges.map(({ node }) => node)
  const { t } = useTranslation("page_news")
  const tMeta = t("meta")
  const tPage = t("page")

  return (
    <Layout>
      <Seo title={tMeta.title} description={tMeta.description} />
      <section className="section">
        <div className="container is-max-desktop">
          <h1 className="title is-1 mb-6">{tPage.title}</h1>
          {news.map(news => {
            return <NewsThumbnail news={news} key={news.id} />
          })}
        </div>
      </section>
      <DualSplit />
    </Layout>
  )
}
export default NewsHomepage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    news: allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      filter: { language: { eq: $language } }
    ) {
      edges {
        node {
          id
          slug {
            current
          }
          title
          description
          _updatedAt
          _createdAt
          mainImage {
            asset {
              thumbnail: gatsbyImageData(height: 128, width: 128)
            }
          }
        }
      }
    }
  }
`
